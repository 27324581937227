import React from "react";
import { FaAddressBook } from "react-icons/fa";
import { BsFillPhoneFill } from "react-icons/bs";
import { MdEmail } from "react-icons/md";

function ContactAddress({ t }) {
  return (
    <div className="connectSec">
      <div className="row">
        <div className="col-sm-6 col-lg-3 d-flex">
          <div className="feature-box text-center w-100 d-flex flex-column">
            <div className="feature-box-content flex-grow-1">
              <BsFillPhoneFill />
              <h4>{t("Phone Number")}</h4>
              <p>
                <a href="tel:+00971502137884">+ 00971 50213 7884</a>
                <br />
                <a href="tel:+00971509719465">+ 00971 50971 9465</a>
              </p>
            </div>
          </div>
        </div>
        <div className="col-sm-6 col-lg-3 d-flex">
          <div className="feature-box text-center w-100 d-flex flex-column">
            <div className="feature-box-content flex-grow-1">
              <MdEmail />
              <h4>{t("E-mail Address")}</h4>
              <p>
                <a href="mailto:sales@fthuae.com">sales@fthuae.com</a>
              </p>
            </div>
          </div>
        </div>
        <div className="col-sm-6 col-lg-6 d-flex">
          <div className="feature-box text-center w-100 d-flex flex-column">
            <div className="feature-box-content flex-grow-1">
              <FaAddressBook />
              <h4>{t("Address")}</h4>
              <p>
                FTH Electronics L.L.C Al Qusais 2, Damascus Street, Al Rubaya Building, Office No: 321, Adjacent to TIME Grand Plaza Hotel, Al Qusais Dubai PO. Box: 232603
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactAddress;
