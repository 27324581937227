import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { FiChevronRight } from "react-icons/fi";
import { useGetMenuListQuery } from "../../../components/products/productSlice";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { base_url } from "../../../server";

function MobileMenu() {
  const isLogin = JSON.parse(window?.localStorage?.getItem("isLogin"));

  const { data: categoryData } = useGetMenuListQuery();

  const [data, setData] = useState(null);
  const [showSelectedSubMenu, setShowSelectedSubMenu] = useState({});
  const baseUrl = base_url();

  const getData = async () => {
    try {
      const res = await axios.get(`${baseUrl}category/filter`, {
        withCredentials: true,
      });
      setData(res.data);
    } catch (error) {
      console.error("An error occurred while fetching data:", error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const { t } = useTranslation();

  const openSubMenu = (name) => {
    setShowSelectedSubMenu((prev) => {
      const updatedState = {};
      Object.keys(prev).forEach((key) => {
        updatedState[key] = false;
      });

      updatedState[name] = !prev[name];
      return updatedState;
    });
  };

  return (
    <div
      className="collapse navbar-collapse mean-menu show"
      id="navbarSupportedContent"
    >
      <ul className="navbar-nav m-0 p-0">
        <li className="nav-item">
          <Link to="/" className="nav-link">{t('Home')}</Link>
        </li>
        <li className="nav-item">
          <Link to="/about" className="nav-link">
            About Us
            <i className="bx bx-chevron-down"></i>
          </Link>
        </li>

        <li className="nav-item">
          <Link to="/products" className="nav-link">
            {t("Products")}
            <i className="bx bx-chevron-down"></i>
          </Link>
        </li>

        {data &&
          data.map((item, i) => (
            <div className="add" key={i}>
              <li className="nav-item my-item">
                <Link
                  to="#"
                  className="nav-link"
                  onClick={() => openSubMenu(item.title.name)}
                >
                  {item?.title?.name}
                  <i className="bx bx-chevron-down"></i>
                </Link>
                {item?.Submenu?.length > 0 && (
                  <ul
                    className={`dropdown-menu dropAgro drop ${showSelectedSubMenu[item.title.name] ? "active" : ""
                      } `}
                  >
                    <div className="row">
                      <div className="col-lg-3 width-100pr">
                        <div className="menuList" style={{ paddingTop: "0px" }}>
                          <ul>
                            {item.Submenu &&
                              item.Submenu.map((subItem) => (
                                <li className="nav-item" key={subItem._id}>
                                  <Link
                                    to={`product/category/${subItem._id}`}
                                    className="nav-link sub_menu_Text"
                                  >
                                    <FiChevronRight /> {subItem.name}
                                  </Link>
                                </li>
                              ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </ul>
                )}
              </li>
              <p>{!showSelectedSubMenu[item.title.name] ? " + " : " - "} </p>
            </div>
          ))}
        <li className="nav-item">
          <Link to="/contact" className="nav-link">
            Contact Us
            <i className="bx bx-chevron-down"></i>
          </Link>
        </li>
        {/* {isLogin && (
          <li className="nav-item">
            <Link to="/" className="nav-link">
              {t("Services")}
            </Link>
          </li>
        )} */}
      </ul>
    </div>
  );
}

export default MobileMenu;
