


import React from "react";

// import a1 from '../../../assets/img/leatzmeat/about-chicken.png'
import { TiTick } from "react-icons/ti";
function AboutStory({ data, t }) {
  return (
    <>
      <div className="aboutSection p-30">
        <div className="container">

          <div className="row">
            {/*  <div className="col-lg-6">
              <div className="image">
                <img src={a1} alt="" />
              </div>
            </div> */}
            <div className="col-lg-12">
              <div className="container">
                <h3 className="subtitle">{t('ABOUT US')}</h3>
                <p className="">
                  {/* {data?.about_company} */}
                  Welcome to FTH Electronics. FTH Electronics is U.A.E's leading provider of the wireless paging systems and services across the region since 2016, specializing mainly in on-site paging systems & Wireless Communication devices. Our products are widely used in large Food Courts, Offices, Restaurants, Resorts, Hospitals, and Cafes, as well as in Government facilities and Industrial sites. Our affordable systems are guaranteed not only to meet but to exceed customers' business expectations. We offer a variety of hospitality paging systems that make it possible to increase a venue's service speed and boost turnover with better on-demand service. The range includes mini paging system coaster and paddle style pagers for fast-food, server call for kitchen staff. With our knowledge and technical expertise, we are able to custom-make systems to suit our clients' specific needs and utilize the latest innovations as they become available. We always strive to provide our customers with the best possible products, support, and services, which has been the foundation of our success for many years. You certainly can count on us. At FTH, we are more than just restaurant pagers. Our amazing apps for Restaurants, Healthcare, Retail, and a host of other industries are powerful, affordable, and intuitive. Our high-tech tools combine text messaging and paging so you and your customers have a choice of Restaurant Paging Systems, Healthcare Paging Systems, Church Nursery Paging Systems, Pharmacy Paging Systems, Auto Dealer Paging Systems, Hotel and Resort Paging Systems, Beach Calling Systems, Maid Paging Systems, and Office Paging Systems.
                </p>
                {/* <p>
                  {t('about-1')}
                </p> */}
              </div>
            </div>
          </div>

        </div>
      </div>
    </>
  );
}

export default AboutStory;
