import { useEffect, useState, useTransition } from "react";
import { Button, Table } from "react-bootstrap";
import { GrView } from "react-icons/gr";
import { FcCancel } from "react-icons/fc";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { TbShoppingCartPlus } from "react-icons/tb";
import ModalCancel from "./ModalCancel";
import {
  useGetAllStatusOrdersQuery,
  useGetPickUpPointsQuery,
} from "../../components/products/productSlice";
import { useTranslation } from "react-i18next";
import { base_url } from "../../server";
function MyAccountDetail() {
  const isLogin = window.localStorage.getItem("isLogin");
  const idUser = window.localStorage.getItem("user_id");

  const navigate = useNavigate();
  useEffect(() => {
    if (isLogin === "false") {
      navigate("/login");
      return;
    }
  }, []);
  const baseUrl = base_url();
  const [data, setData] = useState(null);
  const token1 = window.localStorage.getItem("token");
  const fechData = async () => {
    const res = await axios.get(`${baseUrl}order/getorderbyuser`, {
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        Authorization: `Bearer ${token1}`,
      },
    });
    setData(res.data);
  };
  useEffect(() => {
    fechData();
  }, []);

  window.localStorage.setItem("Invoice", "");

  const [modalShow, setModalShow] = useState(false);
  const [val, setVal] = useState(null);
  const sendValues = (val) => {
    setModalShow(true);
    setVal(val);
  };

  const sendCancel = () => {
    navigate("/cancellOrders");
  };

  const { data: pickup, isLoading } = useGetAllStatusOrdersQuery();

  const getIdstatus = async (e) => {
    if (e.target.value === "1") {
      fechData();
    } else {
      const res = await axios.get(
        `${baseUrl}orderStatusTransaction/filter/user/${e.target.value}`,
        {
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            Authorization: `Bearer ${token1}`,
          },
        }
      );
      setData(res.data);
    }
  };
  const { t, i18n } = useTranslation();

  return (
    <>
      <h2 style={{ display: "flex", justifyContent: "center" }}>
        {t("Purchase History")}
      </h2>
      <div
        style={{
          display: "flex",
          justifyContent: "start",
          width: "1294px",
          margin: "auto",
          cursor: "pointer",
          justifyContent: "space-between",
        }}
      >
        <h5 onClick={sendCancel}>{t("See Your Cancel Orders")}</h5>
        <div style={{ width: "300px" }}>
          <select
            className="form-select"
            onChange={getIdstatus}
            aria-label="Default select example"
          >
            <option value="1">All Status</option>
            {pickup &&
              pickup.map((item) => {
                return (
                  <option key={item._id} value={item._id}>
                    {item?.orderStatusName}
                  </option>
                );
              })}
          </select>
        </div>
      </div>
      <div className="container classOverflow" style={{ margin: "20px auto" }}>
        <Table
          bordered
          hover
          className="table aiz-table mb-0 footable footable-1 breakpoint-xl"
        >
          <thead>
            <tr className="footable-header">
              {/* <th>{t('Order Code')}</th> */}
              <th>{t("Order No")}</th>
              <th>{t("Order Date")}</th>
              <th>{t("Order Amount")}</th>
              <th>{t("Order Delivery Status")}</th>
              <th>{t("Order Payment Status")}</th>
              <th>{t("Delivery Type")}</th>
              <th>{t("Action")}</th>
              <th>{t("Cancel")}</th>
              <th>{t("Re-Order")}</th>
            </tr>
          </thead>
          <tbody>
            {data ? (
              data.map((item, i) => {
                return (
                  <tr key={i}>
                    {/* <td>{item?._id}</td> */}
                    <td>{item?.referenceNo}</td>
                    <td>{item?.status?.[0]?.createdAt}</td>

                    <td style={{ textAlign: "right", paddingRight: "20px" }}>
                      {item?.currency?.symbol ? item?.currency?.symbol : "QAR"}{" "}
                      {item.grandTotal}
                    </td>
                    <td>
                      {item?.status[0]?.orderStatusName && (
                        <strong>{item?.status[0]?.orderStatusName}</strong>
                      )}
                    </td>
                    <td>{item?.payment_type}</td>
                    <td>{item?.deliveryType}</td>
                    {/* <td>---</td> */}
                    {/* <td>{item?.products[0]?.deliveryType} {item?.products[0]?.pickupPoints?.pickupPoint_name}</td> */}
                    <td style={{ display: "table-cell" }}>
                      <Button variant="">
                        {" "}
                        <Link to={`/order-detail/${item._id}`}>
                          <GrView />
                        </Link>
                      </Button>
                    </td>
                    <td style={{ display: "table-cell" }}>
                      <Button variant="" onClick={() => sendValues(item)}>
                        <FcCancel />
                      </Button>
                    </td>
                    <td style={{ display: "table-cell" }}>
                      <Button variant="">
                        {" "}
                        <TbShoppingCartPlus />
                      </Button>
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <th>
                  <div className="preloaderCount">
                    <div className="spinner-border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </div>
                </th>
              </tr>
            )}
          </tbody>
        </Table>

        {modalShow && (
          <ModalCancel
            show={modalShow}
            val={val}
            fechData={fechData}
            onHide={() => setModalShow(false)}
          />
        )}
      </div>
    </>
  );
}
export default MyAccountDetail;
