import React from "react";
import { Link } from "react-router-dom";
import pageHeaderBg from "../../../assets/img/page-header-bg.jpeg";

function AboutBanner() {
  return (
    <>
      <section
        className="aboutBanner p-30"
        style={{ background: `url(${pageHeaderBg})`, backgroundPosition: "bottom",backgroundRepeat:"no-repeat",backgroundSize:"100%" }}
      >
        <div className="container ">
          <div className="aboutBannerInfo">
            <h2 className="py-5">ABOUT US</h2>
            {/* <Link to="/contact" className="btn btn-dark">
              Contact
            </Link> */}
          </div>
        </div>
      </section>
    </>
  );
}

export default AboutBanner;
