import React from "react";
import cardImg from "../../../assets/img/about-seller/Office-2.jpg";
import cardImgpager from "../../../assets/img/about-seller/fthpager.jpg";
function AboutMajorw() {
  return (
    <div className="aboutSection p-30">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 col-md-6 col-12">
            <picture>
              <figure>
                <img src={cardImg} alt className="w-100" />
              </figure>
            </picture>
          </div>
          <div className="col-lg-6 col-md-6 col-12">
            <h4 className="text-primary">
              Our major wireless paging product line includes:
            </h4>
            <p>Our major wireless paging product line includes:</p>
            <ol className>
              <li>All in one Restaurant paging system</li>
              <li>Guest paging system</li>
              <li>aboutComp.tablecall</li>
              <li>Nurse paging system</li>
              <li>Office boy paging system</li>
              <li>Beach Butler paging system</li>
              <li>server call paging system</li>
              <li>Staff paging system</li>
              <li>Fast food paging system</li>
              <li>Customer management system</li>
              <li>Restaurant Table Tracker system</li>
              <li>Hotel Trolley Tracke</li>
            </ol>
          </div>
          <div className="col-lg-6 col-md-6 col-12">
            <p className="text-justify">
              Our products are widely being used in large Food Courts, offices,
              Restaurants, Resorts, Hospitals, Cafes, Cinemas, Hyper Market, as
              well as in Government facilities and Industrial sites. We also
              provide maintenance and technical support. Our affordable systems
              are guaranteed not only to meet but to exceed customer’s business
              expectations. We offer a variety of hospitality paging systems
              which makes it possible to increase a venue’s service speed and
              boost turnover with better on-demand service. The range includes
              mini paging system coaster and paddle style pagers for fast-food,
              server call for kitchen staff, we advise on which pager is best
              suited for each operations. We always strive to provide our
              customers with the best possible products, support and services
              which has been the foundation of our success for many years. You
              certainly can count on us. Great coverage and reliability.
            </p>
          </div>
          <div className="col-lg-6 col-md-6 col-12">
            <div className="px-5">
              <h4 className="">
                <strong>FTH UAE Pager To Makes Life Better</strong>
              </h4>
              <picture>
                <figure>
                  <img src={cardImgpager} alt="about img" className="w-75" />
                </figure>
              </picture>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutMajorw;
