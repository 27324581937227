import React from "react";

function ContactMap() {
  return (
    <>
      <div className="col-xl-6">
        <div className="card p-4 box-sizing">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3607.984702719491!2d55.37807882483981!3d25.271100028704364!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f5d561640ddd3%3A0xb17469a67640e081!2sFTH%20Electronics%20L.L.C!5e0!3m2!1sen!2sin!4v1729331789370!5m2!1sen!2sin"
            width="100%"
            height="450"
            id="frame1"
            allowFullScreen={true}
          ></iframe>
        </div>
      </div>

    </>
  );
}

export default ContactMap;
